
<template>
  <div class="main-content">


    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="card">
      <div class="card-body">
        <b-tabs
            active-nav-item-class="nav nav-tabs"
            content-class="mt-3"
        >
          <b-tab title="Edit Outlet" active>
            <b-container>
              <b-card  title="Edit outlet" class="card-icon mb-30 p-16">
                <b-row>
                  <b-col lg="12" md="12" sm="12">
                    <form action="#" class="form-horizontal "  method="post" v-on:submit.prevent="validateBeforeSubmitEditOutlet">
                      <input type="hidden" name="_token" />
                      <div class="form-body">
                        <div class="row" >
                          <div class="col-md-4">
                            <div class="form-group ">
                              <label class="control-label "><strong>Name:</strong></label> <br/>
                              <div >
                                <input class="form-control" type="text"  v-model="outlet.name" />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group ">
                              <label class="control-label "><strong>Phone number:</strong></label> <br/>
                              <div >
                                <input class="form-control" type="text"  v-model="outlet.phoneNumber" />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group ">
                              <label class="control-label "><strong>Floor level:</strong></label> <br/>
                              <div >
                                <select class="form-control" v-model="outlet.floorLevel">
                                  <option :value="floor_level" v-for="floor_level in floor_levels"> {{ floor_level }}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" >

                          <div class="col-md-4">
                            <div class="form-group ">
                              <label class="control-label "><strong>Category:</strong></label> <br/>
                              <div >
                                <select class="form-control" v-model="outlet.category">
                                  <option :value="category.id" v-for="category in categories"> {{ category.name }}</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group ">
                              <label class="control-label "><strong>Shop No:</strong></label> <br/>
                              <div >
                                <input class="form-control" type="text"  v-model="outlet.shopNo" />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group ">
                              <label class="control-label "><strong>Website URL:</strong></label> <br/>
                              <div >
                                <input class="form-control" type="text"  v-model="outlet.website" />
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row" >
                          <div class="col-md-4">
                            <div class="form-group ">
                              <label class="control-label "><strong>Facebook:</strong></label> <br/>
                              <div >
                                <input class="form-control" type="text"  v-model="outlet.facebook" />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group ">
                              <label class="control-label "><strong>Twitter:</strong></label> <br/>
                              <div >
                                <input class="form-control" type="text"  v-model="outlet.twitter" />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group ">
                              <label class="control-label "><strong>Instagram:</strong></label> <br/>
                              <div >
                                <input class="form-control" type="text"  v-model="outlet.instagram" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" >

                          <div class="col-md-4">
                            <div class="form-group ">
                              <label class="control-label "><strong>Youtube:</strong></label> <br/>
                              <div >
                                <input class="form-control" type="text"  v-model="outlet.youtube" />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group ">
                              <label class="control-label "><strong>Terminal Serial No.:</strong></label> <br/>
                              <div >
                                <input class="form-control" type="text"  v-model="outlet.terminalSerialNo" />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group ">
                              <label class="control-label "><strong>Loyalty Status<sup class="help text text-danger">*</sup>:</strong></label> <br/>
                              <div >
                                <label for="loyalty_status_enrolled">
                                  <input id="loyalty_status_enrolled" type="radio" v-model="outlet.loyaltyStatus" value="ENROLLED" /> ENROLLED
                                </label>
                                <label for="loyalty_status_not_enrolled">
                                  <input id="loyalty_status_not_enrolled" type="radio" v-model="outlet.loyaltyStatus" value="NOT_ENROLLED" /> NOT ENROLLED
                                </label>
                              </div>
                            </div>
                          </div>


                        </div>

                        <div class="row">
                          <div class="col-md-3">
                            Weekday
                            <div class="form-group ">
                              <label class="control-label "><strong>Opening time:</strong></label> <br/>
                              <div >
                                <date-picker style="width: 100%;" v-model="outlet.openingTime"  format="HH:mm" valueType="format" type="time"></date-picker>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <br/>
                            <div class="form-group ">
                              <label class="control-label "><strong>Closing time:</strong></label> <br/>
                              <div >
                                <date-picker style="width: 100%;" v-model="outlet.closingTime" format="HH:mm" valueType="format" type="time"></date-picker>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            Weekends
                            <div class="form-group ">
                              <label class="control-label "><strong>Opening time:</strong></label> <br/>
                              <div >
                                <date-picker style="width: 100%;" v-model="outlet.openingTimeWeekend"  format="HH:mm" valueType="format" type="time"></date-picker>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <br/>
                            <div class="form-group ">
                              <label class="control-label "><strong>Closing time:</strong></label> <br/>
                              <div >
                                <date-picker style="width: 100%;" v-model="outlet.closingTimeWeekend" format="HH:mm" valueType="format" type="time"></date-picker>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" >
                          <div class="col-md-6">
                            <div class="form-group ">
                              <label class="control-label ">Outlet type:</label> <br/>
                              <div >
                                <select v-model="outlet.outletType" class="form-control" name="outlet type">
                                  <option value=""> -- Select -- </option>
                                  <option value="NORMAL" >Retail</option>
                                  <option value="RESTAURANT" >  Food</option>
                                  <option value="ENTERTAINMENT" > Entertainment</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group ">
                              <label class="control-label "><strong>Users:</strong></label> <br/>
                              <div >

                                <v-select
                                    label="name"
                                    v-model="selected_users"
                                    dir="ltr"
                                    multiple
                                    :options="users"
                                />

                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" >

                          <div class="col-md-6">
                            <div class="form-group ">
                              <label class="control-label "><strong>Netsuite ID:</strong></label> <br/>
                              <div >
                                <input class="form-control" type="text"  v-model="outlet.netsuiteId" />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group ">
                              <label class="control-label "><strong>Parking Voucher Authorisation:</strong></label> <br/>
                              <div >
                                <label for="parking_voucher_authorised">
                                  <input name="parking_voucher_authorisation" id="parking_voucher_authorised" type="radio" v-model="outlet.parkingVoucherAuthorization" value="AUTHORIZED" /> Authorised
                                </label> &nbsp;&nbsp;
                                <label for="parking_voucher_not_authorised">
                                  <input name="parking_voucher_authorisation" id="parking_voucher_not_authorised" type="radio" v-model="outlet.parkingVoucherAuthorization" value="UNAUTHORIZED" /> Unauthorised
                                </label>
                              </div>
                            </div>
                            <div class="form-group" v-if="outlet.parkingVoucherAuthorization === 'AUTHORIZED'">
                              <label class="control-label "><strong>Voucher Payment Type:</strong></label> <br/>
                              <div >
                                <label >
                                  <input name="parking_voucher_payment_type" type="radio" v-model="outlet.parkingVoucherPaymentType" value="POSTPAID" /> Postpaid
                                </label>&nbsp;&nbsp;
                                <label >
                                  <input  name="parking_voucher_payment_type" type="radio" v-model="outlet.parkingVoucherPaymentType" value="PREPAID" /> Prepaid
                                </label>
                              </div>
                            </div>

                          </div>
                          <div class="col-md-3">
                            <div class="form-group" v-if="outlet.parkingVoucherAuthorization === 'AUTHORIZED' && outlet.parkingVoucherPaymentType === 'PREPAID'">
                              <label class="control-label "><strong>Amount for prepaid:</strong></label> <br/>
                              <div >
                                <input class="form-control" type="number"  v-model="outlet.prepaidVoucherParkingAmount" />
                              </div>
                            </div>
                            <div class="form-group " v-if="outlet.parkingVoucherAuthorization === 'AUTHORIZED' && outlet.parkingVoucherPaymentType === 'POSTPAID'">
                              <label class="control-label "><strong>Maximum parking voucher duration(hours):</strong></label> <br/>
                              <div >
                                <select class="form-control" v-model="outlet.maximumVoucherParkingHours">
                                  <option v-for="maximumParkingHour in maximumParkingHours">{{ maximumParkingHour }}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" >

                          <div class="col-md-6">
                            <div class="form-group ">
                              <label class="control-label "><strong>Logo:</strong></label> <br/>
                              <div v-if="outlet.logo == '' || !outlet.logo">
                                <input class="form-control" type="file"  ref="logo" @change="handleLogoImageUpload" />
                              </div>
                              <div v-else>
                                <img style="max-height: 200px;" :src="outlet.logo" class="img img-responsive" /> <br/>
                                <a style="margin-top: 10px" class="btn btn-danger" href="#" @click="removeLogo">Delete</a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group ">
                              <label class="control-label "><strong>Banner:</strong></label> <br/>
                              <div v-if="outlet.banner == '' || !outlet.banner">
                                <input class="form-control" type="file"  ref="banner" @change="handleBannerImageUpload" />
                              </div>
                              <div v-else>
                                <img style="max-height: 200px;" :src="outlet.banner" class="img img-responsive" /> <br/>
                                <a style="margin-top: 10px" class="btn btn-danger" href="#" @click="removeBanner">Delete</a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" >
                          <div class="col-md-12">
                            <label class="control-label "><strong>Description:</strong></label> <br/>
                            <textarea class="form-control" rows="7" style="width: 100%;" v-model="outlet.description"></textarea>
                          </div>
                        </div>


                        <div class="row" >
                          <div class="col-md-12">
                            <hr/>
                          </div>
                        </div>

                        <div class="row" >
                          <div class="col-md-12">
                            <button class="btn btn-danger float-right" style="color:#fff;" type="submit"> Submit </button>
                          </div>
                        </div>
                      </div>

                    </form>
                  </b-col>
                </b-row>

              </b-card>
            </b-container>
          </b-tab>
          <b-tab title="Products">
            <div class="row">
              <div class="col-md-3">
                <b-input-group append="Find" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input id="inline-form-input-username" placeholder="Search product"></b-input>
                </b-input-group>
              </div>
              <div class="col-md-9">
                <b-button class="btn btn-danger float-right" v-b-modal.add-product>Add new</b-button>
              </div>
            </div>
            <br/>
            <b-table striped hover :items="products" :fields="product_columns">
              <template v-slot:cell(logo)="data">
                <img style="width: 100px;height: 50px;" :src="data.value" class="img img-thumbnail img-responsive" />
              </template>

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="options">
                  <b-dropdown-item @click="openEditProductModal(data.item)"  >Edit</b-dropdown-item>
                  <b-dropdown-item @click="openDeleteOutletProduct(data.item)"  >Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Parking Rates">
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                <form action="#" class="form-horizontal"  method="post" v-on:submit.prevent="saveParkingRateData">
                  <input type="hidden" name="_token" />
                  <div class="form-body">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group ">
                          <label class="control-label ">Start Date:</label> <br/>
                          <div >
                            <date-picker style="width: 100%;" value-type="format" v-model="parkingRate.startDate" placeholder="Select date"></date-picker>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group ">
                          <label class="control-label ">End Date:</label> <br/>
                          <div >
                            <date-picker style="width: 100%;" value-type="format" v-model="parkingRate.endDate" placeholder="Select date"></date-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" >
                      <div class="col-md-12">
                        <div class="form-group ">
                          <label class="control-label ">Rates:</label> <br/>
                          <div >
                            <table class="table table-bordered">
                              <tr>
                                <th>Min Time (Minutes)</th>
                                <th>Max Time (Minutes)</th>
                                <th>Amount</th>
                                <th></th>
                              </tr>
                              <tr v-for="(rate, index) in parkingRate.parkingRates">
                                <td>
                                  <input type="number" class="form-control" v-model="rate.minTime" />
                                </td>
                                <td>
                                  <input type="number" class="form-control" v-model="rate.maxTime" />
                                </td>
                                <td>
                                  <input type="number" class="form-control" v-model="rate.amount" />
                                </td>
                                <td>
                                  <b-button
                                      type="button"
                                      @click="removeParkingRate(index)"
                                      variant="danger"
                                      class="btn-icon"
                                  >
                                    <feather-icon icon="ArchiveIcon" />
                                  </b-button>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div>
                            <button type="button" class="btn btn-warning btn-sm float-right" @click="addParkingRateItem">Add</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-5" >
                      <div class="col-md-12">
                        <button type="submit" class="float-right btn btn-success">Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-3"></div>
            </div>

          </b-tab>
        </b-tabs>
      </div>
    </div>

    <b-modal id="add-product" title="Add Product" @cancel="true" @ok="validateBeforeSubmitNewProduct"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="product.name" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Description:</label> <br/>
                <div >
                  <textarea v-model="product.description" class="form-control" style="width: 100%;" cols="5"></textarea>
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Base Price:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="product.basePrice" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Discount Price:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="product.discountPrice" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Image:</label> <br/>
                <div >
                  <input class="form-control" type="file"   />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal id="edit-product" title="Edit Product" @cancel="true" @ok="validateBeforeSubmitEditProduct"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="product.name" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Description:</label> <br/>
                <div >
                  <textarea v-model="product.description" class="form-control" style="width: 100%;" cols="5"></textarea>
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Base Price:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="product.basePrice" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Discount Price:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="product.discountPrice" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Image:</label> <br/>
                <div >
                  <input class="form-control" type="file"   />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Multiselect from 'vue-multiselect';

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import vSelect from 'vue-select'
  import Button from "@/views/components/button/Button.vue";

export default {
  data() {
    return {
      maximumParkingHours:[1,2,3,4],
      selected_users:[],
      disabled:false,
      isLoading: false,
      fullPage: true,
      outlets:[],
      users:[],
      outlet_types:["NORMAL","RESTAURANT"],
      floor_levels:[-1,0,1,2,3,4,5,6,7,8,9,10],
      outlet:{
        id:0,
        name:"",
        phoneNumber:"",
        email:"",
        website:"",
        facebook:"",
        twitter:"",
        instagram:"",
        youtube:"",
        description:"",
        shopNo:"",
        openingTime:"",
        closingTime:"",
        logo:"",
        banner:"",
        category:"",
        floorLevel:"",
        users:[],
        outletType:"",
        openingTimeWeekend:"",
        closingTimeWeekend:"",
        terminalSerialNo:"",
        loyaltyStatus:"",
        netsuiteId:"",
        parkingVoucherAuthorization:"",
        maximumVoucherParkingHours:"",
        parkingVoucherPaymentType:"",
        prepaidVoucherParkingAmount:""
      },
      categories:[],
      logo:"",
      banner:"",
      merchant_role:["Merchant","Cashier"],
      product_columns:["created","productImages","name","basePrice","discountPrice","options"],
      products:[],
      product:{
        id:0,
        name:"",
        description:"",
        basePrice:"",
        discountPrice:"",
        images:[]
      },
      parkingRate:{
        id:0,
        status:"ACTIVE",
        startDate:"",
        endDate:"",
        parkingRates:[{
          id:0,
          minTime:"",
          maxTime:"",
          amount:""
        }]
      }
    };
  },
  components: {
    Button,
    Loading,
    DatePicker,
    Multiselect,
    vSelect
  },
  mounted (){
    this.getCategoriesData();
    this.getProducts();
    this.getUsersByRoleData(this.merchant_role)
    this.getOutletParkingRateData();
  },
  methods: {
    ...mapActions(["getOutletParkingRate","addParkingRate","getCategories","editOutlet","getOutlet","getUsersByRole","createOutletProduct","editOutletProduct","deleteOutletProduct","getOutletProducts"]),
    getOutletParkingRateData() {
      let self = this;
      this.getOutletParkingRate({outletId: this.$route.params.id})
          .then((parkingRate) => {
            self.parkingRate = parkingRate;
            self.parkingRate.startDate = parkingRate.startDate.split("T")[0]
            self.parkingRate.endDate = parkingRate.endDate.split("T")[0]
            console.log(self.parkingRate)
          })
          .catch(error => {

          })
    },
    addParkingRateItem(){
      this.parkingRate.parkingRates.push({
        id:0,
        minTime:"",
        maxTime:"",
        amount:""
      });
    },
    removeParkingRate(index) {
      this.parkingRate.parkingRates.splice(index,1)
    },
    saveParkingRateData(){
      let self = this;

      const maxTime = this.parkingRate.parkingRates.reduce((max, item) => Math.max(max, item.maxTime), -Infinity);
      const maximumDurationAllowed = this.outlet.maximumVoucherParkingHours * 60;
      console.log(maxTime)
      console.log(maximumDurationAllowed)

      if(maxTime <= maximumDurationAllowed) {
        this.isLoading = true;

        this.parkingRate.outletId = this.$route.params.id;
        this.parkingRate.startDate = this.parkingRate.startDate+" 00:00"
        this.parkingRate.endDate = this.parkingRate.endDate+" 23:59"
        this.addParkingRate( this.parkingRate)
            .then(() => {
              self.isLoading = false;

              self.$bvToast.toast("Parking rates saved.", {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 5000,
                appendToast: false
              });

              this.getOutletParkingRateData();

            })
            .catch(error => {
              self.isLoading = false;

              self.$bvToast.toast(error.message, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });

            })
      } else {
        self.$bvToast.toast("Maximum duration allowed is: "+this.outlet.maximumVoucherParkingHours+" hours", {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: false
        });
      }


    },
    getUsersByRoleData(role_arr){
      let self = this;
      this.getUsersByRole({roles: role_arr})
        .then(function (users) {
          self.users = users;
          self.getOutletDetails();
        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        });
    },

    openEditProductModal(product){
      this.product = product;
      this.product.images = product.productImages;
      this.$bvModal.show('edit-product');
    },

    removeLogo(){
      this.outlet.logo = "";
    },
    removeBanner(){
      this.outlet.banner = "";
    },
    validateBeforeSubmitNewProduct() {
      this.isLoading = true;

      let self = this;
      this.product.outletId = this.$route.params.id;
      this.createOutletProduct(this.product).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Product added.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getProducts();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    validateBeforeSubmitEditProduct() {
      this.isLoading = true;

      let self = this;
      this.product.outletId = this.$route.params.id;
      this.editOutletProduct(this.product).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Product saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getProducts();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    getOutletDetails(){
      let self = this;
      this.getOutlet({outlet_id: this.$route.params.id})
        .then(function (outlet) {
          self.outlet = outlet;
          self.outlet.logo = outlet.logo_url;
          self.outlet.banner = outlet.banner_url;

          if(outlet.users.length > 0){
            console.log("Updating selected users .... ");
            let outletUsers = self.users.filter(function (user) {
              return outlet.users.includes(Number(user.id));
            });

             self.selected_users = [...outletUsers]

            console.log(self.selected_users);
          }

        })
        .catch(function (error) {

        })
    },
    getProducts(){
      let self = this;
      this.getOutletProducts({outletId: this.$route.params.id})
          .then(function (products) {
            self.products = products;
          })
          .catch(function (error) {

            if( typeof error.response.status != "undefined"){
              if(error.response.status == 401){
                location.replace("/login");
              }

            }
          })
    },

    handleLogoImageUpload(){
      this.logo = this.$refs.logo.files[0];
    },
    handleBannerImageUpload(){
      this.banner = this.$refs.banner.files[0];
    },
    getCategoriesData(){
      let self = this;
      this.getCategories()
        .then(function (categories) {
          self.categories = categories;
        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    validateBeforeSubmitEditOutlet(){

      this.isLoading = true;

      let self = this;

      let formdata = new FormData();


      if(this.logo != ""){
        formdata.append("logo", this.logo);
      }

      if(this.banner != ""){
        formdata.append("banner", this.banner);
      }

      let userIds = this.selected_users.map(function (selectedUser) {
          return selectedUser.id;
      });

      formdata.append("id", this.outlet.id);
      formdata.append("name", this.outlet.name);
      formdata.append("phoneNumber", this.outlet.phoneNumber);
      formdata.append("email", this.outlet.email);
      formdata.append("website", this.outlet.website);
      formdata.append("facebook", this.outlet.facebook);
      formdata.append("twitter", this.outlet.twitter);
      formdata.append("instagram", this.outlet.instagram);
      formdata.append("youtube", this.outlet.youtube);
      formdata.append("description", this.outlet.description);
      formdata.append("shopNo", this.outlet.shopNo);
      formdata.append("netsuiteId", this.outlet.netsuiteId);
      formdata.append("openingTime", this.outlet.openingTime);
      formdata.append("closingTime", this.outlet.closingTime);
      formdata.append("openingTimeWeekend", this.outlet.openingTimeWeekend);
      formdata.append("closingTimeWeekend", this.outlet.closingTimeWeekend);
      formdata.append("category", this.outlet.category);
      formdata.append("users", userIds);
      formdata.append("floorLevel", this.outlet.floorLevel);
      formdata.append("outletType", this.outlet.outletType);
      formdata.append("terminalSerialNo", this.outlet.terminalSerialNo);
      formdata.append("loyaltyStatus", this.outlet.loyaltyStatus);
      formdata.append("parkingVoucherAuthorization", this.outlet.parkingVoucherAuthorization);
      formdata.append("maximumVoucherParkingHours", this.outlet.maximumVoucherParkingHours ?
          this.outlet.maximumVoucherParkingHours : 0);
      formdata.append("parkingVoucherPaymentType", this.outlet.parkingVoucherPaymentType);
      formdata.append("prepaidVoucherParkingAmount", this.outlet.prepaidVoucherParkingAmount ?
          this.outlet.prepaidVoucherParkingAmount : 0);

      this.editOutlet(formdata).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Outlet saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        //this.$router.push("/app/pages/outlets");


      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

          /*if( typeof error.response.status != "undefined"){
             if(error.response.status == 401){
               location.replace("/app/sessions/signIn");
             }

          }
          else{




          }*/
        })
    },
    openDeleteOutletProduct(product){

      this.product = product;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.product.name +'?')
          .then(value => {

            if(value){
              self.confirmDeleteOutletProduct();
            }

          })
          .catch(err => {
            // An error occurred
          })
    },
    confirmDeleteOutletProduct() {
      this.isLoading = true;

      let self = this;
      this.deleteOutletProduct({id: this.product.id}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Product deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getProducts();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
